import { useState, useCallback } from 'react';
import api from 'services/api';

type Data = {
  id: number,
  email: string,
  provider: string,
  uid: string,
  name: string,
  url_name: string,
  deleted_at: null,
  url: string,
  lead_value: null,
  acquisition_value: null,
  active: true,
  additional_info: string,
  phone_number: null,
  fee_percentage: number,
};

type Response = {
  data: {
    data?: Data,
    errors?: string[],
  },
  status: number
};

type Api = {
  request: (resource: any) => Promise<Response>,
  loading: boolean,
  status?: number,
  data?: Data
  error?: { errors?: string[] },
};

const useApi = ():Api => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState();
  const [error, setError] = useState<{ errors?: string[] }>();

  const request = useCallback(async (resource) => {
    try {
      setLoading(true);

      const response = await api(resource);
      setStatus(response.status);
      setData(response.data);

      return response;
    } catch (err:any) {
      setError(err?.data);
      setStatus(err?.status);
      return err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request,
    loading,
    status,
    data,
    error,
  };
};

export default useApi;
