import {
  parseISO, format, isValid, parse,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatDate = (date:string, formatString = 'dd/MM/yyyy'):string | null => {
  const isoParsedDate = parseISO(date);
  if (isValid(isoParsedDate)) {
    return format(isoParsedDate, formatString, { locale: ptBR });
  }

  return null;
};

export const parseDate = (dateString:string, dateFormat = 'dd/MM/yyyy'):Date => (
  parse(dateString, dateFormat, new Date(), { locale: ptBR })
);

export const safeFormatDateToISOString = (date:Date):string | undefined => (date ? format(date, 'yyyy-MM-dd') : undefined);
