import React from 'react';
import { TableCell } from '@mui/material';
import { useHomeContext } from '../context';

type TableCellCustomProps = {
  index: number,
  children: React.ReactNode,
};

const TableCellCustom = (
  { index, children }: TableCellCustomProps,
):JSX.Element => {
  const { affiliations } = useHomeContext();

  return (
    <TableCell
      style={{
        border: (affiliations && index === affiliations?.length - 1)
          ? 'none'
          : 'auto',
      }}
    >
      {children}
    </TableCell>
  );
};

export default TableCellCustom;
