import React from 'react';
import {
  Unstable_Grid2 as Grid, Paper, Box, Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ItemRow from './ItemRow';
import Rules from './Rules';
import { useDetailsContext } from '../context';

const DetailsCard = ():JSX.Element => {
  const history = useHistory();

  const { profile } = useDetailsContext();

  return (
    <Paper elevation={0}>
      <Box padding={3}>
        <Grid container spacing={3}>
          <ItemRow label="Nome" value={profile?.name} />
          <ItemRow label="E-mail" value={profile?.email} />
          <ItemRow label="Blog/Instagram" value={profile?.urlName} />
          <ItemRow label="Url" value={profile?.url} />
          <ItemRow label="Telefone" value={profile?.phoneNumber || 'Não informado'} />
          <Rules />
        </Grid>
        <Box display="flex" flex="1" justifyContent="flex-end" marginTop={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push('/painel/meus-dados/editar')}
          >
            Editar perfil
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default DetailsCard;
