import React from 'react';
import { Unstable_Grid2 as Grid, CircularProgress } from '@mui/material';
import ListHeader from './components/ListHeader';
import Overview from './components/Overview';
import AffiliationsList from './components/AffiliationsList';
import SubtitleCollapse from './components/SubtitleCollapse';
import Chart from './components/Chart';
import { HomeProvider, useHomeContext } from './context';

const Page = ():JSX.Element => {
  const { loading } = useHomeContext();

  if (loading) return <CircularProgress />;

  return (
    <Grid container spacing={3}>
      <Overview />
      <Chart />
      <ListHeader />
      <AffiliationsList />
      <SubtitleCollapse />
    </Grid>
  );
};

const Home = ():JSX.Element => (
  <HomeProvider>
    <Page />
  </HomeProvider>
);

export default Home;
