import { useHistory, useLocation } from 'react-router-dom';
import {
  ListItem, ListItemIcon, Typography,
} from '@mui/material';

type Props = {
  text: string,
  icon: React.ReactNode,
  link?: string,
  onClick?: () => void,
};

const Item = ({
  icon, text, onClick, link,
}:Props):JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const active = location.pathname === link;

  const action = () => {
    if (onClick) {
      onClick();
    } else if (link) {
      history.push(link);
    }
  };

  return (
    <ListItem button onClick={action}>
      <ListItemIcon sx={{ paddingLeft: 2, color: active ? 'primary.main' : 'low.light' }}>
        {icon}
      </ListItemIcon>
      <Typography fontWeight={active ? 600 : 'normal'} color={active ? 'initial' : 'low.medium'}>
        {text}
      </Typography>
    </ListItem>
  );
};
export default Item;
