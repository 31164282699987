import {
  ApolloClient, HttpLink, ApolloLink, InMemoryCache, from, NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as auth from 'services/auth';
import { relayStylePagination } from '@apollo/client/utilities';

const defaultClientOptions:NormalizedCacheObject = {
  fetchOptions: {
    mode: 'no-cors',
  },
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
};

const publicHttpLink = new HttpLink({
  uri: `${import.meta.env.VITE_URL_API}/api/v1/graphql`,
});

const publicAuthMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'gql-target': 'open',
    },
  }));

  return forward(operation);
});

const affiliatesHttpLink = new HttpLink({
  uri: `${import.meta.env.VITE_URL_API}/api/v1/affiliate/graphql`,
});

const affiliatesAuthMiddleware = new ApolloLink((operation, forward) => {
  const { accessToken, client, uid } = auth.getCredentials();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'access-token': accessToken,
      client,
      uid,
    },
  }));

  return forward(operation);
});

const affiliatesErrorMiddleware = onError(({ networkError }) => {
  if (
    networkError
    && 'statusCode' in networkError
    && networkError.statusCode === 401
    && auth.hasCredentials()
  ) {
    auth.clearCredentials();
    const win: Window = window;
    win.location = window.location.origin;
  }
});

export const publicClient = new ApolloClient({
  link: from([publicAuthMiddleware, publicHttpLink]),
  cache: new InMemoryCache(),
  ...defaultClientOptions,
});

export const affiliatesClient = new ApolloClient({
  link: from([affiliatesAuthMiddleware, affiliatesErrorMiddleware, affiliatesHttpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          affiliations: relayStylePagination(),
        },
      },
    },
  }),
  ...defaultClientOptions,
});
