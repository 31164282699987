import React from 'react';
import {
  List, ListItem, ListItemText, Checkbox, Box, Typography,
} from '@mui/material';
import { FilterItem } from '../types';

type FilterItemsProps = {
  itemsList: FilterItem[],
  title: string,
  initialValue: FilterItem[],
  onChange: React.Dispatch<React.SetStateAction<FilterItem[]>>
};

const FilterItems = ({
  itemsList, title, initialValue, onChange,
}: FilterItemsProps):JSX.Element => {
  const hasValue = (item: FilterItem) => initialValue.find((i) => i.value === item.value);

  const handleClick = (item: FilterItem) => {
    if (hasValue(item)) {
      const index = initialValue.indexOf(item);
      const values = [...initialValue];
      values.splice(index, 1);
      onChange(values);
    } else {
      onChange([...initialValue, item]);
    }
  };

  return (
    <>
      <Box marginX={2} marginTop={2}>
        <Typography fontWeight={500} variant="body2">
          {title}
        </Typography>
      </Box>
      <List>
        {itemsList.map((item) => (
          <ListItem
            key={item.value}
            onClick={() => handleClick(item)}
            dense
            button
          >
            <ListItemText primary={item.label} />
            <Checkbox checked={!!hasValue(item)} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default FilterItems;
