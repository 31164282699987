import React, { useState } from 'react';
import {
  Paper, Box, Button, Tabs, Tab, Typography,
} from '@mui/material';
import { TextareaInput } from '@resolvvi/pink-flow';
import { useDetailsContext } from '../context';

const LinksCard = ():JSX.Element => {
  const { getLink, currentLink } = useDetailsContext();

  const [activeTab, setActiveTab] = useState(0);

  const onTabClick = (
    _event:React.ChangeEvent<any>,
    newValue: React.SetStateAction<number>,
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Paper elevation={0}>
      <Box padding={3} color="#666666">
        <Typography fontWeight={600}>
          Resolvvi Links
        </Typography>
        <Box marginTop={1}>
          <Tabs value={activeTab} onChange={onTabClick} indicatorColor="primary" centered>
            <Tab label="Home" onClick={() => getLink()} />
            <Tab label="Voos" onClick={() => getLink('FLIGHT_PROBLEM')} />
            <Tab label="Nome negativado" onClick={() => getLink('NEGATIVATION_PROBLEM')} />
          </Tabs>
        </Box>
        <Box marginTop={2} marginBottom={3}>
          <TextareaInput value={currentLink} disabled rows={3} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigator.clipboard.writeText(currentLink)}
          >
            Copiar Código
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default LinksCard;
