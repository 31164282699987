import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions, Button, Box, Typography,
} from '@mui/material';
import { PasswordInput } from '@resolvvi/pink-flow';
import GearsIcon from 'assets/icons/gears.svg';
import { useEditContext } from '../context';

const ConfirmUpdateDialog = ():JSX.Element => {
  const {
    showConfirmUpdateDialog,
    closeConfirmUpdateDialog,
    onSubmit,
    password,
    setPassword,
    loading,
    errors,
  } = useEditContext();

  const [showError, setShowError] = useState(false);

  const validatePassword = () => {
    if (password.length >= 6) {
      setShowError(false);
      onSubmit();
    } else {
      setShowError(true);
    }
  };

  return (
    <Dialog open={showConfirmUpdateDialog} onClose={closeConfirmUpdateDialog} maxWidth="xs" fullWidth>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Box display="flex" justifyContent="center">
          <img src={GearsIcon} alt="Engrenagens" style={{ width: 185, height: 146 }} />
        </Box>
        <Box marginY={2}>
          <Typography fontWeight={600} align="center" variant="h6">
            Confirmar atualização
          </Typography>
        </Box>
        <Typography color="textSecondary" align="center">
          Confirme sua senha atual para atualizar seu perfil
        </Typography>
        <Box marginY={3}>
          <PasswordInput
            name="password"
            label="Senha atual"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box marginY={3}>
          <Typography variant="body2" color="error" align="center">
            {showError ? 'A Senha deve ter no mínimo 6 caracteres.' : errors?.sendData?.message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={validatePassword}
          disabled={loading}
        >
          Atualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateDialog;
