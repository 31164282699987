import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CaretLeft } from '@phosphor-icons/react';

const Navigation = ({ title }: { title: string }):JSX.Element => {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" color="low.medium">
        <IconButton aria-label="Voltar" onClick={() => history.goBack()} size="small">
          <CaretLeft size={16} />
        </IconButton>
        <Typography variant="h6" fontWeight={600} color="textSecondary">{title}</Typography>
      </Box>
    </Box>
  );
};

export default Navigation;
