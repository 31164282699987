import React from 'react';
import { Unstable_Grid2 as Grid, Box, Button } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { safeFormatDateToISOString } from 'utils/date';
import { FunnelSimple } from '@phosphor-icons/react';
import ptLocale from 'date-fns/locale/pt-BR';
import Filter from './Filter';
import { useHomeContext } from '../context';

const ListHeader = ():JSX.Element => {
  const {
    statusFilters,
    setStatusFilters,
    kindFilters,
    setKindFilters,
    showFilter,
    setShowFilter,
    monthFilter,
    setMonthFilter,
  } = useHomeContext();

  const hasFilters = statusFilters.length > 0 || kindFilters.length > 0;

  const filters = [...statusFilters, ...kindFilters];

  const filtereds = filters.map((filter) => filter.label).join(', ');

  const cleanFilters = () => {
    setStatusFilters([]);
    setKindFilters([]);
    setMonthFilter(undefined);
  };

  return (
    <Grid xs={12}>
      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
        <Box mr={2} marginBottom={1.5}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptLocale}>
            <DesktopDatePicker
              openTo="year"
              views={['year', 'month']}
              label="Mês de afiliação"
              value={monthFilter as unknown as Date}
              onChange={(value) => setMonthFilter(safeFormatDateToISOString(value as Date))}
            />
          </LocalizationProvider>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{ backgroundColor: '#fff' }}
          endIcon={<FunnelSimple size={16} />}
          onClick={() => setShowFilter(true)}
        >
          Filtros
          {hasFilters && ` - ${filtereds}`}
        </Button>
        {hasFilters && (
        <Button
          size="large"
          color="primary"
          onClick={cleanFilters}
        >
          Limpar
        </Button>
        )}
        {showFilter && (
          <Box position="absolute">
            <Filter />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ListHeader;
