/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as auth from 'services/auth';

const api = axios.create({
  baseURL: `${import.meta.env.VITE_URL_API}/api/v1/affiliate`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const REDIRECT_EXCEPTIONS = [
  '/',
  '/autenticacao',
];

api.interceptors.response.use(
  (response) => {
    auth.updateWhenHeadersChange(response.headers);
    return response;
  },
  (error) => {
    const { response } = error;
    auth.updateWhenHeadersChange(error.response.headers);

    if (response.status === 401 && auth.hasCredentials()) {
      auth.clearCredentials();

      if (!REDIRECT_EXCEPTIONS.includes(window.location.pathname)) {
        const win: Window = window;
        win.location = window.location.origin;
      }
    }

    return Promise.reject(response);
  },
);

api.interceptors.request.use(
  (config) => {
    if (auth.hasCredentials()) {
      const { accessToken, client, uid } = auth.getCredentials();
      config.headers['access-token'] = accessToken;
      config.headers.client = client;
      config.headers.uid = uid;
    }
    return config;
  },
);

export default api;
