import React from 'react';
import { Unstable_Grid2 as Grid, Box, Typography } from '@mui/material';

type ItemRowProps = {
  label: string,
  value?: string,
};

const ItemRow = ({
  label, value = '',
}: ItemRowProps):JSX.Element => (
  <Grid xs={12}>
    <Box marginBottom={0.5}>
      <Typography fontWeight={600} color="textSecondary">
        {label}
      </Typography>
    </Box>
    <Typography color="textSecondary">
      {value}
    </Typography>
  </Grid>
);

export default ItemRow;
