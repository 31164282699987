import React, { useState, useContext, useCallback } from 'react';
import useApi from 'hooks/useApi';
import { validateAuth, signOut } from 'services/api/requests/auth';
import * as auth from 'services/auth';
import { AuthProviderType, AuthProviderProps } from './types';

const Context = React.createContext({} as AuthProviderType);
const useAuthContext = ():AuthProviderType => useContext(Context);

const AuthProvider = ({ children }:AuthProviderProps):JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const { request } = useApi();

  const checkAuthentication = useCallback(async () => {
    try {
      const response = await request(validateAuth);

      if (response?.status === 200) {
        setEmail(response?.data?.data?.email ?? '');
        setName(response?.data?.data?.name ?? '');
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      setIsAuthenticated(false);
    }
  }, [request]);

  const logout = useCallback(async () => {
    try {
      await request(signOut);
    } finally {
      setIsAuthenticated(false);
      auth.clearCredentials();
    }
  }, [request]);

  const value = {
    email,
    setEmail,
    name,
    setName,
    isAuthenticated,
    checkAuthentication,
    setIsAuthenticated,
    logout,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export { AuthProvider, useAuthContext };
