type Return = {
  method: string,
  url: string,
};

type SignIn = Return & {
  data: {
    email: string,
    password: string,
  }
};

type SignInProps = {
  email: string,
  password: string,
};

export const signIn = ({ email, password }:SignInProps):SignIn => ({
  method: 'post',
  url: '/auth/sign_in',
  data: {
    email,
    password,
  },
});

export const signOut = {
  method: 'delete',
  url: '/auth/sign_out',
};

export const validateAuth = {
  method: 'get',
  url: '/auth/validate_token',
};

type Register = Return & {
  data: {
    email: string,
    password: string,
    password_confirmation: string,
    name: string,
    phone_number: string,
  }
};

type RegisterProps = {
  email: string,
  password: string,
  passwordConfirmation: string,
  name: string,
  phone: string,
};

export const register = ({
  email, password, passwordConfirmation, name, phone,
}:RegisterProps):Register => ({
  method: 'post',
  url: '/auth',
  data: {
    email,
    password,
    password_confirmation: passwordConfirmation,
    name,
    phone_number: phone,
  },
});
