import React from 'react';
import {
  Card, Box, Divider, Typography,
} from '@mui/material';
import { useHomeContext } from '../context';

const AffiliationsMobileList = ():JSX.Element => {
  const { affiliations } = useHomeContext();

  return (
    <Box>
      {affiliations?.length === 0 && (
      <Box padding={3} display="flex" justifyContent="center" width="100%">
        <Typography variant="body2" fontWeight={400}>
          Lista vazia
        </Typography>
      </Box>
      )}
      {affiliations?.map((affiliation) => (
        <Box marginBottom={2} key={affiliation.id}>
          <Card style={{ boxShadow: 'none' }}>
            <Box marginX={3}>
              <Box marginY={3}>
                <Typography variant="caption" color="textSecondary">Email</Typography>
                <Typography color="textSecondary">
                  {affiliation.originEmail}
                </Typography>
              </Box>
              <Divider />
              <Box marginY={3}>
                <Typography variant="caption" color="textSecondary">Origem</Typography>
                <Typography color="textSecondary">{affiliation.rule.kindI18n}</Typography>
              </Box>
              <Divider />
              <Box marginY={3}>
                <Typography variant="caption" color="textSecondary">Status</Typography>
                <Typography color="textSecondary">{affiliation.statusI18n}</Typography>
              </Box>
              <Divider />
              <Box marginY={3}>
                <Typography variant="caption" color="textSecondary">Valor</Typography>
                <Typography color="textSecondary">{affiliation.commission.format}</Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      ))}
    </Box>
  );
};

export default AffiliationsMobileList;
