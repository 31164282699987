import { useForm, UseFormMethods } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { UPDATE_AFFILIATE_PASSWORD } from 'services/graphql/publicMutations';
import { publicClient } from 'services/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  UpdateAffiliatePasswordMutation,
  UpdateAffiliatePasswordMutationVariables,
} from 'types/publicMutations';
import yup from 'utils/yup';

type UseFormType = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  onSubmit: () => void,
  loading: boolean,
};

type Props = {
  token: string,
  setShowSuccessDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setShowErrorDialog: React.Dispatch<React.SetStateAction<boolean>>,
};

const schema = yup.object().shape({
  password: yup.string().required().label('Senha'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem.').label('Confirmar senha'),
});

export default ({
  token, setShowSuccessDialog, setShowErrorDialog,
}: Props):UseFormType => {
  const {
    register, handleSubmit, errors, setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [updateAffiliatePassword, { loading }] = useMutation<
  UpdateAffiliatePasswordMutation,
  UpdateAffiliatePasswordMutationVariables
  >(UPDATE_AFFILIATE_PASSWORD, {
    client: publicClient,
  });

  const onSubmit = handleSubmit(async (formData) => {
    const response = await updateAffiliatePassword({
      variables: {
        token,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
      },
    });

    if (!response?.data?.result?.errors) {
      setShowSuccessDialog(true);
    } else if (
      response?.data?.result?.errors?.messages?.every(
        (message: { attribute: string; }) => message.attribute === 'resetPasswordToken',
      )
    ) {
      setShowErrorDialog(true);
    } else {
      setError('errorUpdatePassword', {
        type: 'error-update-password',
        message: 'Ops! Algo deu errado. Não foi possível realizar a alteração da senha.',
      });
    }
  });

  return {
    register,
    errors,
    onSubmit,
    loading,
  };
};
