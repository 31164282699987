import {
  TextField, Box, Button, Typography,
} from '@mui/material';
import useForm from './useForm';

const EmailForm = (
  { goToPassword }:{ goToPassword: () => void },
):JSX.Element => {
  const {
    register,
    errors,
    onSubmit,
  } = useForm({ goToPassword });

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h6" fontWeight="bold" align="center">Acessar sua conta</Typography>
      <Box marginTop={2} marginBottom={2}>
        <TextField name="email" label="E-mail" inputRef={register} placeholder="Seu e-mail" required fullWidth />
        <Typography variant="caption" color="error">{errors?.email?.message}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" size="large" type="submit">Próximo</Button>
      </Box>
    </form>
  );
};

export default EmailForm;
