import { useForm, UseFormMethods } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { REQUEST_AFFILIATE_PASSWORD_CHANGE } from 'services/graphql/publicMutations';
import { publicClient } from 'services/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  RequestAffiliatePasswordChangeMutation,
  RequestAffiliatePasswordChangeMutationVariables,
} from 'types/publicMutations';
import yup from 'utils/yup';

type UseFormType = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  onSubmit: () => void,
  loading: boolean,
};

type Props = {
  setShowSuccessDialog: React.Dispatch<React.SetStateAction<boolean>>,
};

const schema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
});

export default ({ setShowSuccessDialog }:Props):UseFormType => {
  const {
    register, handleSubmit, errors, setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [requestAffiliatePasswordChange, { loading }] = useMutation<
  RequestAffiliatePasswordChangeMutation,
  RequestAffiliatePasswordChangeMutationVariables
  >(
    REQUEST_AFFILIATE_PASSWORD_CHANGE, {
      client: publicClient,
    },
  );

  const onSubmit = handleSubmit(async (formData) => {
    const response = await requestAffiliatePasswordChange({
      variables: {
        email: formData.email,
      },
    });

    if (response?.data?.result?.success) {
      setShowSuccessDialog(true);
    } else {
      setError('email', {
        type: 'check-recover',
        message: 'Não foi possível solicitar a troca de senha. E-mail não reconhecido.',
      });
    }
  });

  return {
    register,
    onSubmit,
    errors,
    loading,
  };
};
