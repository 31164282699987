import React, { useState } from 'react';
import {
  Unstable_Grid2 as Grid, Paper, Box, Collapse, Typography,
} from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

const SubtitleCollapse = ():JSX.Element => {
  const [showSubtitle, setShowSubtitle] = useState(false);

  return (
    <Grid xs={12}>
      <Paper elevation={0}>
        <Box paddingX={3} paddingY={2} color="#666666">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            width={245}
            onClick={() => setShowSubtitle((current) => !current)}
          >
            <Box marginRight={2}>
              <Typography fontWeight={600} variant="body2">
                O que cada status significa?
              </Typography>
            </Box>
            {showSubtitle ? (
              <CaretUp size={16} />
            ) : (
              <CaretDown size={16} />
            )}
          </Box>
          <Collapse in={showSubtitle}>
            <Box marginTop={3}>
              <Box marginBottom={1}>
                <Typography fontWeight={600} variant="body2">Em andamento</Typography>
              </Box>
              <Typography fontWeight={400} variant="body2">
                A afiliação foi criada, mas ainda não preencheu os requisitos para ser válida
              </Typography>
            </Box>
            <Box marginTop={3}>
              <Box marginBottom={1}>
                <Typography fontWeight={600} variant="body2">Pagamento pendente</Typography>
              </Box>
              <Typography fontWeight={400} variant="body2">
                Tudo certo com a afiliação e você já pode receber a comissão
              </Typography>
            </Box>
            <Box marginTop={3}>
              <Box marginBottom={1}>
                <Typography fontWeight={600} variant="body2">Pago</Typography>
              </Box>
              <Typography fontWeight={400} variant="body2">
                Você já foi comissionado pela afiliação
              </Typography>
            </Box>
            <Box marginTop={3}>
              <Box marginBottom={1}>
                <Typography fontWeight={600} variant="body2">Cancelado</Typography>
              </Box>
              <Typography fontWeight={400} variant="body2">
                A afiliação foi cancelada antes de finalizar
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Paper>
    </Grid>
  );
};

export default SubtitleCollapse;
