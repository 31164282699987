import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { useAuthContext } from 'contexts/Auth';
import ResolvviLogo from 'assets/logo_resolvvi.svg';
import EmailForm from './components/EmailForm';
import PasswordForm from './components/PasswordForm';

const Login = ():JSX.Element => {
  const history = useHistory();
  const { checkAuthentication, isAuthenticated, setIsAuthenticated } = useAuthContext();

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/painel');
    }
  }, [history, isAuthenticated]);

  const afterSubmit = () => setIsAuthenticated(true);

  const [currentStep, setCurrentStep] = useState('email');

  const goToPassword = () => setCurrentStep('password');
  const backToEmail = () => setCurrentStep('email');

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100%"
      padding={2}
    >
      <Paper elevation={0} sx={{ width: '100%', maxWidth: 500 }}>
        <Box padding={3}>
          <Box display="flex" justifyContent="center" marginBottom={1}>
            <img src={ResolvviLogo} alt="Logo" height={36} />
          </Box>
          {currentStep === 'email'
            ? (
              <EmailForm goToPassword={goToPassword} />
            ) : (
              <PasswordForm
                backToEmail={backToEmail}
                afterSubmit={afterSubmit}
                goToRecoverPassword={() => history.push('/autenticacao/recuperar-senha')}
              />
            )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
