import { gql } from '@apollo/client';

export const REQUEST_AFFILIATE_PASSWORD_CHANGE = gql`
  mutation RequestAffiliatePasswordChange($email: String!) {
    result: requestAffiliatePasswordChange(input: {
      email: $email
    }) {
      success
    }
  }
`;

export const UPDATE_AFFILIATE_PASSWORD = gql`
  mutation UpdateAffiliatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    result: updateAffiliatePassword(input: {
      token: $token,
      password: $password,
      passwordConfirmation: $passwordConfirmation
    }) {
      errors {
        messages {
          attribute
          messages
        }
      }
    }
  }
`;
