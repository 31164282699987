import React from 'react';
import { Unstable_Grid2 as Grid, CircularProgress, Typography } from '@mui/material';
import { DetailsProvider, useDetailsContext } from './context';
import DetailsCard from './components/DetailsCard';
import LinksCard from './components/LinksCard';

const Page = ():JSX.Element => {
  const { loading } = useDetailsContext();

  if (loading) return <CircularProgress />;

  return (
    <Grid container spacing={3} direction="column">
      <Grid xs={12}>
        <Typography variant="h6" fontWeight={600} color="textSecondary">
          Meus dados
        </Typography>
      </Grid>
      <Grid xs={12} md={10} lg={8} xl={6}>
        <DetailsCard />
      </Grid>
      <Grid xs={12} md={10} lg={8} xl={6}>
        <LinksCard />
      </Grid>
    </Grid>
  );
};

const Details = ():JSX.Element => (
  <DetailsProvider>
    <Page />
  </DetailsProvider>
);

export default Details;
