import { useAuthContext } from 'contexts/Auth';
import { useForm, UseFormMethods } from 'react-hook-form';
import { signIn } from 'services/api/requests/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import useApi from 'hooks/useApi';

type UseFormType = Partial<Pick<UseFormMethods, 'register' | 'errors' | 'setError'>> & {
  onSubmit: () => void,
  loading: boolean,
};

const schema = yup.object().shape({
  password: yup.string()
    .required()
    .label('Senha'),
});

export default (
  { afterSubmit }:{ afterSubmit: () => void },
):UseFormType => {
  const { email, setName } = useAuthContext();
  const { request, loading } = useApi();

  const {
    register, handleSubmit, errors, setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (formData) => {
    const response = await request(signIn({ email, password: formData.password }));

    if (response.status === 200) {
      setName(response?.data?.data?.name ?? '');
      afterSubmit();
    } else {
      setError('password', {
        type: 'check-login',
        message: 'Impossível fazer login com as credenciais fornecidas.',
      });
    }
  });

  return {
    register,
    errors,
    setError,
    loading,
    onSubmit,
  };
};
