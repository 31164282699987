import React from 'react';
import { TableCell } from '@mui/material';

type SmallTableCellProps = {
  children: React.ReactNode,
};

const SmallTableCell = (
  { children }: SmallTableCellProps,
):JSX.Element => (
  <TableCell
    style={{
      color: '#666666',
      fontSize: '0.75rem',
      fontWeight: 400,
    }}
    size="small"
  >
    {children}
  </TableCell>
);

export default SmallTableCell;
