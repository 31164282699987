import { useAuthContext } from 'contexts/Auth';
import { useForm, UseFormMethods } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';

type UseFormType = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  onSubmit: () => void;
};

const schema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
});

export default (
  { goToPassword }:{ goToPassword: () => void },
):UseFormType => {
  const { email, setEmail } = useAuthContext();

  const {
    register, errors, handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email },
  });

  const onSubmit = handleSubmit((formData) => {
    if (formData?.email) {
      setEmail(formData.email);
      goToPassword();
    }
  });

  return {
    register,
    errors,
    onSubmit,
  };
};
