import React from 'react';
import {
  Unstable_Grid2 as Grid, Paper, Box, Button, TextField, Typography,
} from '@mui/material';
import { PasswordInput } from '@resolvvi/pink-flow';
import ConfirmUpdateDialog from './ConfirmUpdateDialog';
import { useEditContext } from '../context';

const Form = ():JSX.Element => {
  const {
    profile, errors, register, setShowConfirmUpdateDialog,
  } = useEditContext();

  return (
    <Grid xs={12} md={10} lg={8} xl={6}>
      <Paper elevation={0}>
        <Box padding={3}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Typography fontWeight={600} color="textSecondary">Dados pessoais</Typography>
            </Grid>
            <Grid xs={12}>
              <TextField name="name" label="Nome" defaultValue={profile?.name} inputRef={register} required fullWidth />
              <Typography variant="caption" color="error">{errors?.name?.message}</Typography>
            </Grid>
            <Grid xs={12}>
              <TextField name="email" label="E-mail" defaultValue={profile?.email} inputRef={register} required fullWidth />
              <Typography variant="caption" color="error">{errors?.email?.message}</Typography>
            </Grid>
            <Grid xs={12}>
              <Box marginTop={3}>
                <Typography fontWeight={600} color="textSecondary">Alterar senha</Typography>
              </Box>
            </Grid>
            <Grid xs={12}>
              <PasswordInput name="newPassword" label="Nova senha" inputRef={register} />
              <Typography variant="caption" color="error">{errors?.newPassword?.message}</Typography>
            </Grid>
            <Grid xs={12}>
              <PasswordInput name="newPasswordConfirmation" label="Confirmar nova senha" inputRef={register} />
              <Typography variant="caption" color="error">{errors?.newPasswordConfirmation?.message}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display="flex" flex="1" justifyContent="flex-end" marginTop={3}>
        <Button variant="contained" color="primary" size="large" onClick={() => setShowConfirmUpdateDialog(true)}>
          Editar perfil
        </Button>
      </Box>
      <ConfirmUpdateDialog />
    </Grid>
  );
};

export default Form;
