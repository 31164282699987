import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';

type Props = {
  open: boolean,
  handleClose: () => void,
  contentText: string,
  onConfirmAction: () => void,
};

const ActionAlertDialog = ({
  open, handleClose, contentText, onConfirmAction,
}:Props):JSX.Element => {
  const handleConfirm = () => {
    onConfirmAction();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Atenção!</DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <Typography fontWeight={600}>
          {contentText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleConfirm}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionAlertDialog;
