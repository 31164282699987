import React from 'react';
import {
  Unstable_Grid2 as Grid, Paper, Table, TableBody, Box,
  TableHead, TableRow, useMediaQuery,
  Button, Typography, useTheme,
} from '@mui/material';
import { useHomeContext } from '../context';
import AffiliationsMobileList from './AffiliationsMobileList';
import SmallTableCell from './SmallTableCell';
import TableCell from './TableCell';

const AffiliationsList = ():JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { affiliations, showLoadMore, loadMore } = useHomeContext();

  return (
    <Grid xs={12}>
      {matches ? (
        <Paper elevation={0} style={{ overflow: 'hidden' }}>
          <Table>
            <TableHead>
              <TableRow>
                <SmallTableCell>Email</SmallTableCell>
                <SmallTableCell>Origem</SmallTableCell>
                <SmallTableCell>Status</SmallTableCell>
                <SmallTableCell>Valor</SmallTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiliations?.map((affiliation, index) => (
                <TableRow key={index.toString()}>
                  <TableCell index={index}>{affiliation.originEmail}</TableCell>
                  <TableCell index={index}>{affiliation.rule.kindI18n}</TableCell>
                  <TableCell index={index}>{affiliation.statusI18n}</TableCell>
                  <TableCell index={index}>{affiliation.commission.format}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {affiliations?.length === 0 && (
            <Box padding={3} display="flex" justifyContent="center" width="100%">
              <Typography variant="body2" fontWeight={400}>
                Lista vazia
              </Typography>
            </Box>
          )}
        </Paper>
      ) : <AffiliationsMobileList />}
      {showLoadMore && (
        <Box display="block" marginTop={4} textAlign="center">
          <Button onClick={loadMore} variant="contained" color="primary" size="large">
            Carregar mais
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default AffiliationsList;
