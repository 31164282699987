import React, {
  useContext, useState, useEffect, useMemo, useCallback,
} from 'react';
import { useQuery } from '@apollo/client';
import { GET_AFFILIATIONS, GET_AFFILIATIONS_TOTALS_GROUPED, GET_TOTALS } from 'services/graphql/queries';
import {
  HomeProviderType, HomeProviderProps,
  AffiliationType, FilterItem, RelayPageInfo, AffiliationGroupResponse,
} from './types';

const Context = React.createContext({} as HomeProviderType);

const useHomeContext = ():HomeProviderType => useContext(Context);

const HomeProvider = ({ children }: HomeProviderProps): JSX.Element => {
  const { data: totalsResponse, loading: loadingTotals } = useQuery(GET_TOTALS);
  const {
    data: affiliationsTotalsGrouped,
    loading: loadingAffiliationsTotalsGrouped,
  } = useQuery<AffiliationGroupResponse>(GET_AFFILIATIONS_TOTALS_GROUPED);
  const [pageInfo, setPageInfo] = useState<RelayPageInfo>();
  const {
    data: affiliationsResponse,
    loading: loadingAffiliations,
    refetch,
    fetchMore,
  } = useQuery(GET_AFFILIATIONS);

  useEffect(() => {
    if (affiliationsResponse?.affiliations?.pageInfo) {
      setPageInfo(affiliationsResponse.affiliations.pageInfo);
    }
  }, [affiliationsResponse]);

  const loadMore = useCallback(() => {
    fetchMore({ variables: { after: pageInfo?.endCursor } });
  }, [fetchMore, pageInfo]);

  const affiliations = affiliationsResponse
    ?.affiliations
    ?.edges
    ?.map(({ affiliation }: { affiliation: AffiliationType }) => affiliation);
  const totals = totalsResponse?.profile;
  const showLoadMore = pageInfo?.hasNextPage || false;

  const [statusFilters, setStatusFilters] = useState<FilterItem[]>([]);
  const [kindFilters, setKindFilters] = useState<FilterItem[]>([]);
  const [monthFilter, setMonthFilter] = useState<string>();
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    const statuses = statusFilters.map((item: FilterItem) => item.value);
    const kinds = kindFilters.map((item: FilterItem) => item.value);
    refetch({
      statuses: statuses.length === 0 ? undefined : statuses,
      kinds: kinds.length === 0 ? undefined : kinds,
      month: monthFilter,
    });
  }, [refetch, statusFilters, kindFilters, monthFilter]);

  const value = useMemo(() => ({
    affiliations,
    loading: loadingTotals || loadingAffiliations,
    totals,
    kindFilters,
    setKindFilters,
    statusFilters,
    setStatusFilters,
    showFilter,
    setShowFilter,
    showLoadMore,
    loadMore,
    affiliationsTotalsGrouped,
    loadingAffiliationsTotalsGrouped,
    monthFilter,
    setMonthFilter,
  }), [
    affiliations, affiliationsTotalsGrouped, kindFilters,
    loadMore, loadingAffiliations, loadingAffiliationsTotalsGrouped,
    loadingTotals, monthFilter, showFilter, showLoadMore, statusFilters,
    totals,
  ]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export { HomeProvider, useHomeContext };
