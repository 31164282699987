import { Box, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
  label: string,
  icon: React.ReactNode,
  link?: string,
  onClick?: () => void,
};

const BottomNavigation = ({
  label, icon, link, onClick,
}:Props):JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  const handlePress = () => {
    if (link) {
      history.push(link);
    } else if (onClick) {
      onClick();
    }
  };

  const isActive = location.pathname === link;

  return (
    <Box
      onClick={handlePress}
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={1}
    >
      <Box
        minHeight="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={isActive ? 'secondary.main' : 'low.light'}
      >
        {icon}
      </Box>
      <Typography
        color={isActive ? 'inherit' : 'low.medium'}
        fontWeight={500}
        fontSize="0.75rem"
        align="center"
        style={{ lineHeight: 1.2 }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default BottomNavigation;
