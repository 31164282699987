import {
  AppBar, Box, Button, Typography,
} from '@mui/material';
import { useAuthContext } from 'contexts/Auth';
import ResolvviImage from 'assets/logo_resolvvi.svg';

const drawerWidth = 280;

const Header = ():JSX.Element => {
  const { logout } = useAuthContext();

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
        marginLeft: { xs: 0, md: drawerWidth },
        backgroundColor: 'background.paper',
        boxShadow: { xs: '0px 3px 6px rgba(0, 0, 0, 0.16)', md: 'none' },
        height: '60px',
        borderBottomLeftRadius: { xs: 50, md: 0 },
        borderBottomRightRadius: { xs: 50, md: 0 },
      }}
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        marginRight={{ xs: 0, md: 5 }}
      >
        <Box display={{ xs: 'block', md: 'none' }}>
          <img
            src={ResolvviImage}
            alt="logo"
            width="100px"
          />
        </Box>
        <Button
          size="large"
          sx={{
            width: '100px',
            backgroundColor: 'background.paper',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
            display: { xs: 'none', md: 'block' },
          }}
          onClick={() => logout()}
        >
          <Typography fontWeight={600} color="primary">
            Sair
          </Typography>
        </Button>
      </Box>
    </AppBar>
  );
};

export default Header;
