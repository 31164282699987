import { Box } from '@mui/material';
import BottomTabBar from 'components/BottomTabBar';
import Sidebar from './Sidebar';
import Header from './Header';

type Props = {
  children: React.ReactNode,
};

const Panel = ({ children }:Props):JSX.Element => (
  <div style={{ display: 'flex' }}>
    <Header />
    <Sidebar />
    <Box
      paddingX={3}
      paddingTop={10}
      paddingBottom={{ xs: 12, md: 3 }}
      marginLeft={{ xs: 0, md: '280px' }}
    >
      {children}
    </Box>
    <BottomTabBar />
  </div>
);

export default Panel;
