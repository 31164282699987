import React, {
  useContext, useMemo, useCallback, useState, useEffect,
} from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROFILE } from 'services/graphql/queries';
import { ProfileQuery, ProfileQueryVariables } from 'types/queries';
import {
  DetailsProviderType, DetailsProviderProps,
} from './types';

const Context = React.createContext({} as DetailsProviderType);

const useDetailsContext = ():DetailsProviderType => useContext(Context);

const CLIENT_APP_LINK = 'https://app.resolvvi.com';

const DetailsProvider = ({ children }: DetailsProviderProps): JSX.Element => {
  const [currentLink, setCurrentLink] = useState('');

  const { data, loading } = useQuery<
  ProfileQuery,
  ProfileQueryVariables
  >(GET_PROFILE);

  const profile = data?.profile;
  const link = profile?.link?.split('?')[1];

  useEffect(() => {
    if (link) {
      setCurrentLink(`https://www.resolvvi.com/?${link}`);
    }
  }, [link]);

  const getLink = useCallback((kind?: string) => {
    if (kind === 'FLIGHT_PROBLEM') {
      setCurrentLink(`${CLIENT_APP_LINK}/elegibilidade/voo?${link}`);
    } else if (kind === 'NEGATIVATION_PROBLEM') {
      setCurrentLink(`${CLIENT_APP_LINK}/elegibilidade/nome-negativado?${link}`);
    } else {
      setCurrentLink(`https://www.resolvvi.com/?${link}`);
    }
  }, [link]);

  const value = useMemo(() => ({
    profile,
    loading,
    getLink,
    currentLink,
  }), [
    profile, loading, getLink, currentLink,
  ]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export { DetailsProvider, useDetailsContext };
