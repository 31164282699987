import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';

import Panel from 'components/Panel';
import PrivateRoute from 'components/PrivateRoute';

import Authentication from 'App/Authentication/Login';
import RecoverPassword from 'App/Authentication/RecoverPassword';
import Home from 'App/Home';
import DetailsUserData from 'App/User/Details';
import EditUserData from 'App/User/Edit';

const Routes = ():JSX.Element => (
  <Router>
    <Switch>
      <Route path="/" exact>
        <Redirect to="/autenticacao" />
      </Route>
      <Route path="/autenticacao" exact component={Authentication} />
      <Route path="/autenticacao/recuperar-senha" exact component={RecoverPassword} />
      <Panel>
        <PrivateRoute path="/painel" exact component={Home} />
        <PrivateRoute path="/painel/meus-dados" exact component={DetailsUserData} />
        <PrivateRoute path="/painel/meus-dados/editar" exact component={EditUserData} />
      </Panel>
    </Switch>
  </Router>
);

export default Routes;
