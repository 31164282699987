import React from 'react';
import { Unstable_Grid2 as Grid, Box, Typography } from '@mui/material';
import { useDetailsContext } from '../context';

const Rules = ():JSX.Element => {
  const { profile } = useDetailsContext();

  return (
    <>
      <Grid xs={12}>
        <Box marginBottom={0.5}>
          <Typography fontWeight={600} color="textSecondary">
            Regras
          </Typography>
        </Box>
      </Grid>
      {profile?.rules?.map((rule) => (
        <Grid xs={12} key={rule.id}>
          <Box display="flex">
            <Box width="33%">
              <Typography fontWeight={400} fontSize="0.75rem" color="textSecondary">
                Tipo
              </Typography>
              <Typography fontWeight={400} variant="body2">
                {rule.kindI18n}
              </Typography>
            </Box>
            <Box width="33%">
              <Typography fontWeight={400} fontSize="0.75rem" color="textSecondary">
                Forma de Pagamento
              </Typography>
              <Typography fontWeight={400} variant="body2">
                {rule.paymentKindI18n}
              </Typography>
            </Box>
            <Box width="33%">
              <Typography fontWeight={400} fontSize="0.75rem" color="textSecondary">
                Valor
              </Typography>
              <Typography fontWeight={400} variant="body2">
                {rule.paymentKind === 'fixed' ? rule.fixedValue.format : rule.percentValue}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default Rules;
