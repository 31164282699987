import { useState } from 'react';
import {
  TextField, Button, Box, Typography,
} from '@mui/material';
import { useAuthContext } from 'contexts/Auth';
import ActionAlertDialog from 'components/ActionAlertDialog';
import useForm from './useForm';

const RecoverEmailForm = (
  { backToEmail }:{ backToEmail: () => void },
):JSX.Element => {
  const { email } = useAuthContext();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const {
    register, onSubmit, errors, loading,
  } = useForm({ setShowSuccessDialog });

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h6" fontWeight="bold" align="center">Esqueceu sua senha?</Typography>
      <Box marginTop={1} marginBottom={4}>
        <Typography
          onClick={backToEmail}
          align="center"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Já possui uma conta?
        </Typography>
        <Box marginY={1.5}>
          <TextField name="email" label="E-mail" defaultValue={email} disabled={loading} inputRef={register} required fullWidth />
          <Typography variant="caption" color="error">{errors?.email?.message}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" flex="1">
        <Button type="submit" variant="contained" color="primary" size="large" disabled={loading}>
          Recuperar senha
        </Button>
      </Box>
      <ActionAlertDialog
        open={showSuccessDialog}
        handleClose={() => setShowSuccessDialog(false)}
        contentText="Foi enviado um e-mail de confirmação para o E-mail cadastrado. Acesse o link presente na mensagem."
        onConfirmAction={() => setShowSuccessDialog(false)}
      />
    </form>
  );
};

export default RecoverEmailForm;
