import { gql } from '@apollo/client';

export const UPDATE_AFFILIATE = gql`
  mutation UpdateAffiliate(
    $email: String,
    $name: String,
    $newPassword: String,
    $newPasswordConfirmation: String,
    $password: String
  ) {
    result: updateAffiliate(input: {
      affiliate: {
        email: $email,
        name: $name,
        newPassword: $newPassword,
        newPasswordConfirmation: $newPasswordConfirmation,
        password: $password
      }
    }) {
      errors
    }
  }
`;
