import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { UPDATE_AFFILIATE } from 'services/graphql/mutations';
import {
  UpdateAffiliateMutation, UpdateAffiliateMutationVariables,
} from 'types/mutations';
import yup from 'utils/yup';
import { UseFormType } from './types';

type UseFormProps = {
  password: string,
  afterSubmit: () => void,
};

const schema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  newPassword: yup.string().label('Nova senha'),
  newPasswordConfirmation: yup.string()
    .oneOf([yup.ref('newPassword')], 'As senhas não coincidem.').label('Confirmar senha'),
});

export default ({
  password, afterSubmit,
}: UseFormProps):UseFormType => {
  const {
    handleSubmit, register, errors, setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [updateAffiliate, { loading }] = useMutation<
  UpdateAffiliateMutation, UpdateAffiliateMutationVariables
  >(UPDATE_AFFILIATE);

  const onSubmit = handleSubmit(async (formData) => {
    const response = await updateAffiliate({
      variables: {
        name: formData.name,
        email: formData.email,
        newPassword: formData.newPassword || null,
        newPasswordConfirmation: formData.newPasswordConfirmation || null,
        password,
      },
    });
    if (!response?.data?.result?.errors) {
      afterSubmit();
      window.location.reload();
    } else {
      setError('sendData', {
        type: 'error-send-data',
        message: `Ocorreu um erro ao atualizar os dados: ${response?.data?.result?.errors}`,
      });
    }
  });

  return {
    register,
    onSubmit,
    errors,
    loading,
  };
};
