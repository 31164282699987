export default [
  {
    label: 'Pendente',
    value: 'PENDING_PAYMENT',
  },
  {
    label: 'Em andamento',
    value: 'IN_PROGRESS',
  },
  {
    label: 'Pago',
    value: 'PAID',
  },
  {
    label: 'Cancelado',
    value: 'CANCELLED',
  },
];
