import React from 'react';
import {
  Unstable_Grid2 as Grid, Box, Card, Typography,
} from '@mui/material';
import { useHomeContext } from '../context';

const Overview = ():JSX.Element => {
  const { totals } = useHomeContext();

  return (
    <Grid xs={12}>
      <Box marginBottom={2}>
        <Typography variant="h6" fontWeight={600} color="textSecondary">
          Visão geral da conta
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12} sm={4}>
          <Card>
            <Box
              padding={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography display="block" align="center">Em Andamento</Typography>
              <Typography display="block" align="center" variant="caption" color="textSecondary">
                Quantidade:
                {' '}
                {totals?.inProgressCount}
              </Typography>
              <Typography color="textSecondary" align="center" fontWeight="bold">
                {totals?.inProgressCommission.format}
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} sm={4}>
          <Card>
            <Box
              padding={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography display="block" align="center">Pendente de Pagamento</Typography>
              <Typography display="block" align="center" variant="caption" color="textSecondary">
                Quantidade:
                {' '}
                {totals?.pendingPaymentCount}
              </Typography>
              <Typography color="textSecondary" align="center" fontWeight="bold">
                {totals?.pendingPaymentCommission.format}
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} sm={4}>
          <Card>
            <Box
              padding={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography display="block" align="center">Pagamento Feito</Typography>
              <Typography display="block" align="center" variant="caption" color="textSecondary">
                Quantidade:
                {' '}
                {totals?.paidCount}
              </Typography>
              <Typography color="textSecondary" align="center" fontWeight="bold">
                {totals?.paidCommission.format}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
