import React from 'react';
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from '@resolvvi/pink-flow';
import { ptBR } from '@mui/material/locale';
import { ApolloProvider } from '@apollo/client';
import { affiliatesClient } from 'services/graphql';
import { AuthProvider } from 'contexts/Auth';
import * as Sentry from '@sentry/react';
import Router from './Router';

// @ts-ignore
const materialTheme = createTheme({ ...theme }, ptBR);

if (import.meta.env.VITE_ENV !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_RELEASE,
    environment: import.meta.env.VITE_ENV,
  });
}

const App = ():JSX.Element => (
  <MaterialThemeProvider theme={materialTheme}>
    <CssBaseline />
    <ApolloProvider client={affiliatesClient}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ApolloProvider>
  </MaterialThemeProvider>
);

export default App;
