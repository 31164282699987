import { gql } from '@apollo/client';

export const GET_TOTALS = gql`
  query getTotals {
    profile {
      inProgressCommission: totalAffiliationCommission(statuses: IN_PROGRESS) {
        cents
        format
        normalized
      }
      inProgressCount: totalAffiliations(statuses: IN_PROGRESS)
      pendingPaymentCommission: totalAffiliationCommission(statuses: PENDING_PAYMENT) {
        cents
        format
        normalized
      }
      pendingPaymentCount: totalAffiliations(statuses: PENDING_PAYMENT)
      paidCommission: totalAffiliationCommission(statuses: PAID) {
        cents
        format
        normalized
      }
      paidCount: totalAffiliations(statuses: PAID)
    }
  }
`;

export const GET_AFFILIATIONS = gql`
  query getAffiliations($after: String, $statuses: [AffiliationStatusEnum!], $kinds: [AffiliationRuleKindEnum!], $month: ISO8601Date) {
    affiliations(after: $after, statuses: $statuses, kinds: $kinds, month: $month) {
      edges {
        affiliation: node {
          id
          originEmail
          commission {
            cents
            format
            normalized
          }
          originType
          rule {
            kind
            kindI18n
          }
          status
          statusI18n
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query Profile {
    profile {
      name
      email
      phoneNumber
      link
      url
      urlName
      rules {
        id
        kindI18n
        paymentKind
        paymentKindI18n
        percentValue
        fixedValue {
          cents
          format
          normalized
        }
      }
    }
  }
`;

export const GET_AFFILIATIONS_TOTALS_GROUPED = gql`
  query affiliationsTotalsGrouped {
    groups: affiliationsTotalsGrouped {
      date
      leadCreation
      accountCreation
      orderCreation
      validOrder
      orderWin
    }
  }
`;
