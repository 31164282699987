import React from 'react';
import { Unstable_Grid2 as Grid, CircularProgress } from '@mui/material';
import Navigation from 'components/Navigation';
import Form from './components/Form';
import { EditProvider, useEditContext } from './context';

const Page = ():JSX.Element => {
  const { loadingProfile, profile } = useEditContext();

  if (loadingProfile) return <CircularProgress />;

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Navigation title="Editar meus dados" />
      </Grid>
      {profile && (
        <Form />
      )}
    </Grid>
  );
};

const Edit = () => (
  <EditProvider>
    <Page />
  </EditProvider>
);

export default Edit;
