import { Box, Button, Typography } from '@mui/material';
import { PasswordInput } from '@resolvvi/pink-flow';
import useForm from './useForm';

type Props = {
  backToEmail: () => void,
  afterSubmit: () => void,
  goToRecoverPassword: () => void,
};

const PasswordForm = ({
  backToEmail, afterSubmit, goToRecoverPassword,
}:Props):JSX.Element => {
  const {
    onSubmit,
    register,
    errors,
    loading,
  } = useForm({ afterSubmit });

  return (
    <form onSubmit={onSubmit}>
      <Box marginTop={2}>
        <Typography
          onClick={backToEmail}
          align="center"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Acessar outra conta
        </Typography>
      </Box>
      <Box marginTop={2} marginBottom={2}>
        <PasswordInput name="password" label="Digite sua senha" inputRef={register} required />
        <Typography variant="caption" color="error">{errors?.password?.message}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flex="1">
        <Button
          color="primary"
          size="large"
          onClick={goToRecoverPassword}
          sx={{
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          }}
        >
          Esqueceu a senha?
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>Próximo</Button>
      </Box>
    </form>
  );
};

export default PasswordForm;
