import React from 'react';
import {
  Card, Box, Unstable_Grid2 as Grid, CircularProgress,
} from '@mui/material';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { formatDate } from 'utils/date';
import { useHomeContext } from '../context';
import { camelCaseKinds } from '../affiliationRuleKindList';
import { RuleKind } from '../types';

const COLORS = ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'];

const Filter = ():JSX.Element => {
  const {
    affiliationsTotalsGrouped,
    loadingAffiliationsTotalsGrouped,
  } = useHomeContext();

  if (loadingAffiliationsTotalsGrouped || !affiliationsTotalsGrouped) {
    return <CircularProgress />;
  }

  const avaialableKinds:string[] = [];
  const data = affiliationsTotalsGrouped.groups
    .filter((group) => group.date !== null)
    .map((group) => {
      const date = formatDate(group.date ?? '', 'MM/yyyy');
      const formattedGroup = { date };
      const keys = Object.keys(camelCaseKinds) as RuleKind[];
      keys.forEach(((key: RuleKind) => {
        if (group[key]) {
          if (!avaialableKinds.includes(camelCaseKinds[key])) {
            avaialableKinds.push(camelCaseKinds[key]);
          }
          // @ts-ignore
          formattedGroup[camelCaseKinds[key]] = group[key];
        }
      }));
      return formattedGroup;
    });

  return (
    <Grid xs={12}>
      <Card>
        <Box padding={2}>
          <Box fontSize="1rem" fontWeight="bold" mb={2} textAlign="center">Afiliações mês a mês</Box>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {
              avaialableKinds.map((kind, index) => (
                <Bar dataKey={kind} fill={COLORS[index]} key={kind} />
              ))
            }
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
    </Grid>
  );
};

export default Filter;
