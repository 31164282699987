import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuthContext } from 'contexts/Auth';
import ResolvviLogo from 'assets/logo_resolvvi.svg';
import RecoverEmailForm from './components/RecoverEmailForm';
import NewPasswordForm from './components/NewPasswordForm';

const RecoverPassword = ():JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [token, setToken] = useState<string>();
  const { checkAuthentication, isAuthenticated } = useAuthContext();

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/painel');
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    setToken(query.reset_password_token as string);
  }, [location]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100%"
      padding={2}
    >
      <Paper elevation={0} sx={{ width: '100%', maxWidth: 500 }}>
        <Box padding={3}>
          <Box display="flex" justifyContent="center" marginBottom={1}>
            <img src={ResolvviLogo} alt="Logo" height={36} />
          </Box>
          {token
            ? <NewPasswordForm token={token} />
            : <RecoverEmailForm backToEmail={() => history.push('/autenticacao')} />}
        </Box>
      </Paper>
    </Box>
  );
};

export default RecoverPassword;
