const map = [
  {
    label: 'Criação de lead',
    value: 'LEAD_CREATION',
  },
  {
    label: 'Criação de conta',
    value: 'ACCOUNT_CREATION',
  },
  {
    label: 'Criação de pedido',
    value: 'ORDER_CREATION',
  },
  {
    label: 'Efetivação',
    value: 'VALID_ORDER',
  },
  {
    label: 'Ganho',
    value: 'ORDER_WIN',
  },
];

export const camelCaseKinds = {
  leadCreation: 'Criação de lead',
  accountCreation: 'Criação de conta',
  orderCreation: 'Criação de pedido',
  validOrder: 'Efetivação',
  orderWin: 'Ganho',
};

export const getLabelByValue = (value: string):(string | undefined) => (
  map.find((item) => item.value === value.toUpperCase())?.label
);

export default map;
