import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROFILE } from 'services/graphql/queries';
import { ProfileQuery, ProfileQueryVariables } from 'types/queries';
import {
  EditProviderType, EditProviderProps,
} from './types';
import useForm from './useForm';

const Context = React.createContext({} as EditProviderType);

const useEditContext = ():EditProviderType => useContext(Context);

const EditProvider = ({ children }: EditProviderProps): JSX.Element => {
  const { data, loading: loadingProfile } = useQuery<
  ProfileQuery, ProfileQueryVariables
  >(GET_PROFILE);

  const [showConfirmUpdateDialog, setShowConfirmUpdateDialog] = useState(false);
  const [password, setPassword] = useState('');

  const closeConfirmUpdateDialog = () => {
    setShowConfirmUpdateDialog(false);
    setPassword('');
  };

  const formData = useForm({
    password,
    afterSubmit: closeConfirmUpdateDialog,
  });

  const value = {
    ...formData,
    profile: data?.profile,
    loadingProfile,
    showConfirmUpdateDialog,
    setShowConfirmUpdateDialog,
    closeConfirmUpdateDialog,
    password,
    setPassword,
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export { EditProvider, useEditContext };
