import React from 'react';
import {
  Card, Divider, Box, Button, Typography,
} from '@mui/material';
import { useHomeContext } from '../context';
import FilterItems from './FilterItems';
import affiliationRuleKindList from '../affiliationRuleKindList';
import affiliationStatusEnum from '../affiliationStatusList';

const Filter = ():JSX.Element => {
  const {
    kindFilters,
    setKindFilters,
    statusFilters,
    setStatusFilters,
    setShowFilter,
  } = useHomeContext();

  return (
    <Card>
      <Box padding={2}>
        <Typography fontWeight={500} fontSize="0.75rem" align="center">
          Filtrar por
        </Typography>
      </Box>
      <Divider />
      <Box width={292} maxHeight={250} style={{ overflowY: 'scroll' }}>
        <FilterItems
          title="Status"
          itemsList={affiliationStatusEnum}
          initialValue={statusFilters}
          onChange={setStatusFilters}
        />
        <Divider variant="middle" />
        <FilterItems
          title="Forma de pagamento"
          itemsList={affiliationRuleKindList}
          initialValue={kindFilters}
          onChange={setKindFilters}
        />
      </Box>
      <Box padding={2}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => setShowFilter(false)}
        >
          Fechar
        </Button>
      </Box>
    </Card>
  );
};

export default Filter;
