import { Box } from '@mui/material';
import { useAuthContext } from 'contexts/Auth';
import { DoorOpen, HouseLine, User } from '@phosphor-icons/react';
import BottomNavigation from './components/BottomNavigation';

const BottomTabBar = ():JSX.Element => {
  const { logout } = useAuthContext();

  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      flexDirection="row"
      position="fixed"
      width="100%"
      height={70}
      bottom={0}
      left={0}
      bgcolor="background.paper"
      sx={{
        zIndex: 9999,
        boxShadow: '0px -3px 6px #00000029',
      }}
    >
      <BottomNavigation
        label="painel"
        icon={<HouseLine size={24} />}
        link="/painel"
      />
      <BottomNavigation
        label="meus dados"
        icon={<User size={24} />}
        link="/painel/meus-dados"
      />
      <BottomNavigation
        label="sair"
        icon={<DoorOpen size={24} />}
        onClick={() => logout()}
      />
    </Box>
  );
};

export default BottomTabBar;
