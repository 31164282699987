import ResolvviLogo from 'assets/logo_resolvvi.svg';
import { HouseLine, UserRectangle } from '@phosphor-icons/react';
import { Drawer, List, Box } from '@mui/material';
import Item from './Item';

const drawerWidth = 280;

const Sidebar = ():JSX.Element => {
  const getDrawer = () => (
    <>
      <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
        <img src={ResolvviLogo} alt="logo" width="60%" />
      </Box>
      <List>
        <Item icon={<HouseLine size={24} />} text="Painel" link="/painel" />
        <Item icon={<UserRectangle size={24} />} text="Meus dados" link="/painel/meus-dados" />
      </List>
    </>
  );

  return (
    <Drawer
      sx={{
        display: { xs: 'none', md: 'block' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxShadow: '0px 0px 6px #00000029',
        },
      }}
      variant="permanent"
      open
    >
      {getDrawer()}
    </Drawer>
  );
};

export default Sidebar;
