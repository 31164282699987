/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { hasCredentials } from 'services/auth';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'contexts/Auth';

type Props = RouteProps & {
  component: any,
};

const PrivateRoute = ({
  component: Component, ...rest
}:Props):JSX.Element => {
  const { checkAuthentication, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) {
      checkAuthentication();
    }
  }, [isAuthenticated, checkAuthentication]);

  return (
    <Route
      {...rest}
      render={(props) => (
        hasCredentials()
          ? <Component {...props} />
          : <Redirect to="/" />
      )}
    />
  );
};
export default PrivateRoute;
