type Credentials = {
  accessToken: string,
  client: string,
  uid: string
};

export const setCredentials = ({ accessToken, client, uid }:Credentials):void => (
  localStorage.setItem('@auth', JSON.stringify({
    accessToken, client, uid,
  }))
);

export const clearCredentials = ():void => localStorage.clear();
export const hasCredentials = ():boolean => !!localStorage.getItem('@auth');

export const getCredentials = ():Credentials => hasCredentials() && JSON.parse(localStorage.getItem('@auth') || '{}');
export const getCredential = (key: 'accessToken' | 'client' | 'uid'):(string | null) => {
  if (hasCredentials()) {
    return getCredentials()[key];
  }

  return null;
};

export type Headers = {
  'access-token'?: string,
  client: string,
  uid: string
};

export const updateWhenHeadersChange = (headers: Headers):void => {
  if (headers['access-token'] && headers['access-token'] !== getCredential('accessToken')) {
    setCredentials({
      accessToken: headers['access-token'],
      client: headers.client,
      uid: headers.uid,
    });
  }
};
