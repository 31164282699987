import { useState } from 'react';
import {
  TextField, Button, Box, Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ActionAlertDialog from 'components/ActionAlertDialog';
import useForm from './useForm';

const NewPasswordForm = ({ token }:{ token:string }):JSX.Element => {
  const history = useHistory();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const {
    register, onSubmit, errors,
  } = useForm({ token, setShowSuccessDialog, setShowErrorDialog });

  const goToLogin = () => {
    setShowSuccessDialog(false);
    history.push('/autenticacao');
  };

  const goToRecoveryPassword = () => {
    setShowErrorDialog(false);
    history.push('/autenticacao/recuperar-senha');
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h6" fontWeight="bold" align="center">Criar nova senha</Typography>
      <Box marginTop={1} marginBottom={3}>
        <Typography variant="subtitle2" align="center" fontWeight={500}>
          Vamos criar sua senha!
        </Typography>
        <Box marginY={1.5}>
          <TextField name="password" label="Senha" type="password" inputRef={register} fullWidth />
          <Typography variant="caption" color="error">{errors?.password?.message}</Typography>
        </Box>
        <Box marginY={1.5}>
          <TextField name="passwordConfirmation" label="Confirmar senha" type="password" inputRef={register} fullWidth />
          <Typography variant="caption" color="error">{errors?.passwordConfirmation?.message}</Typography>
        </Box>
        <Typography variant="subtitle2" align="center" color="error">{errors?.errorUpdatePassword?.message}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" flex="1">
        <Button type="submit" variant="contained" color="primary" size="large">
          Próximo
        </Button>
      </Box>
      <ActionAlertDialog
        open={showSuccessDialog}
        handleClose={goToLogin}
        contentText="Sua senha foi alterada com sucesso! Agora você pode acessar sua conta."
        onConfirmAction={goToLogin}
      />
      <ActionAlertDialog
        open={showErrorDialog}
        handleClose={goToRecoveryPassword}
        contentText="Sua definição de senha expirou. Por favor, siga os passos para a recuperação de senha."
        onConfirmAction={goToRecoveryPassword}
      />
    </form>
  );
};

export default NewPasswordForm;
